import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormSelect from "./form-select";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { useEffect } from "react";
import {
  useGetUserCompanies,
  useSwitchCurrentCompany,
} from "../redux/company/hook";
import LogoSVG from "../assets/svg/ropay-logo-white.svg";
import classNames from "classnames";
import { Collapse } from "reactstrap";
import CollapsingIconWhite from "./collapsing-icon-white";
import Button from "./button";
import { closeSidebar } from "../redux/components/components-slice";
import LoadingBar from "./loader-bar";

const Sidebar = () => {
  const dispatch = useDispatch();
  const { company } = useSelector((state) => state.companySlice);
  const { section } = useSelector((state) => state.sectionSlice);
  const [companyOptions, setCompanyOptions] = useState([]);

  const { mutateAsync: switchCurrentCompany } = useSwitchCurrentCompany();

  const { data: companies } = useGetUserCompanies();

  const history = useHistory();
  const [currentLocation, setCurrentLocation] = useState(null);

  const location = useLocation();

  const [sideBar, setSideBar] = useState(null);

  useEffect(() => {
    if (section) {
      setSideBar(section.sidebarItems);
    }
  }, [section]);

  useEffect(() => {
    if (location) {
      if (!currentLocation) {
        setCurrentLocation(location.pathname.split("/")[1]);
      } else {
        if (location.pathname.split("/")[1] !== currentLocation) {
          if (location.pathname.split("/")[1] == "dashboard") {
          }
        }
      }
    }
  }, [location]);

  useEffect(() => {
    if (companies) {
      let computeCompanyOptions = [];

      companies.forEach((company) => {
        computeCompanyOptions.push({
          label: company?.company_name,
          value: company?.id,
        });
      });
      computeCompanyOptions.push({
        label: "Add New Company",
        value: "-1",
      });
      setCompanyOptions(computeCompanyOptions);
    }
  }, [companies]);

  const [loggedInCompany, setLoggedInCompany] = useState(company?.id);

  useEffect(() => {
    if (loggedInCompany) {
      if (loggedInCompany != "-1" && company.id !== loggedInCompany) {
        switchCurrentCompany(loggedInCompany);
        return;
      }

      if (loggedInCompany == "-1") {
        history.push("/onboarding/about-company");
      }
    }
  }, [loggedInCompany]);

  const addCompany = () => {
    history.push("/onboarding/about-company");
  };

  return (
    <div
      className={classNames(
        "lg:flex md:flex min-w-[255px] max-w-[255px] h-screen inset-0 duration-300 flex-col w-full relative overflow-auto select-none",
        {
          "bg-hr-primary-1": section.name === "core hr",
          "bg-secondary-2": section.name === "payroll",
          "bg-spend-primary-1": section.name === "spend management",
        }
      )}
    >
      <div
        className="flex px-[15px] pt-[30px] cursor-pointer"
        onClick={() => {
          history.push("/dashboard");
        }}
      >
        <img src={LogoSVG} alt="" width={"55%"} />
      </div>
      <hr className="!bg-white min-h-[3px] mb-1" />
      {companyOptions.length > 0 ? (
        <div className="flex flex-col items-center w-full">
          <FormSelect
            value={loggedInCompany}
            options={companyOptions}
            onChange={(selected) => {
              setLoggedInCompany(selected);
            }}
            type="default"
            extraClass={
              "!text-white font-bold bg-white bg-opacity-25 !h-[35px] !text-[12px] !border-none min-w-[225px]"
            }
          />
          <div className="w-full flex justify-end -mt-2 -mb-3 duration-300">
            <Button
              text="Add New Company"
              type="button"
              className="flex gap-2 h-[25px] w-fit !hover:text-underline !shadow-none"
              textClass={"!text-[11px]"}
              rightIcon={"add-small"}
              onClick={() => {
                addCompany();
              }}
            />
          </div>
        </div>
      ) : (
        <div className="w-full flex justify-end mt-2 -mb-1">
          <LoadingBar loading={true} sidebar={true} />
        </div>
      )}
      <hr className="!bg-white min-h-[3px] " />
      <div className="flex flex-col justify-between h-full mb-3 gap-2 -mt-2">
        <div className="flex flex-col">
          {section ? (
            <>
              {sideBar?.map((item, index) => (
                <div id={item.id} key={index}>
                  {item.subroute && item.subroute.length > 0 ? (
                    <>
                      <div
                        className={classNames(
                          "flex gap-8 w-full text-color-white font-normal text-[11px] leading-[20px] pl-[20px] !h-[42px] items-center hover:text-color-white hover:font-semibold hover:bg-white hover:bg-opacity-5 cursor-pointer select-non",
                          {
                            "font-extrabold text-[12px] text-color-white bg-white bg-opacity-10":
                              item.paths?.includes(
                                location?.pathname.split("/")[1]
                              ) ||
                              item.paths?.includes(
                                location?.pathname?.split("/")[2]
                              ),
                          }
                        )}
                        onClick={() => {
                          let newArray = JSON.parse(JSON.stringify(sideBar));
                          newArray[index].setsubroute = !item.setsubroute;
                          setSideBar(newArray);
                        }}
                      >
                        <div
                          className={classNames(
                            "flex text-white duration-300",
                            {
                              "font-bold": item.setsubroute,
                            }
                          )}
                        >
                          <img
                            src={item.icon}
                            alt={item.name}
                            className={
                              "pr-[15px] w-[35px] h-[40px] duration-300"
                            }
                          />
                        </div>

                        <div className="text-color-white font-normal text-[11px] flex h-[42px] justify-between w-full items-center hover:text-color-white hover:font-semibold pr-4 -ml-[33px] duration-300">
                          <span
                            className={classNames(
                              "flex text-white duration-300 ",
                              {
                                "font-bold": item.setsubroute,
                              }
                            )}
                          >
                            {item.name}
                          </span>
                          <CollapsingIconWhite
                            defaultPosition="left"
                            isOpen={item.setsubroute}
                          />
                        </div>
                      </div>
                      <div
                        className={classNames("flex flex-col duration-300", {
                          hidden: !item.setsubroute,
                        })}
                      >
                        <Collapse isOpen={item.setsubroute}>
                          <div className="bg-white bg-opacity-10 flex flex-col">
                            {item.subroute.map((subItem, subIndex) => (
                              <NavLink
                                onClick={() => dispatch(closeSidebar())}
                                key={subIndex}
                                to={subItem.route}
                                className="!text-color-white font-normal text-[11px] leading-[20px] flex pl-[35px] !h-[40px] items-center hover:text-color-white hover:font-semibold hover:bg-white hover:bg-opacity-20 duration-300"
                                activeClassName={
                                  "font-extrabold text-[12px] !text-color-white bg-white bg-opacity-10 border-r-4 border-r-white duration-300"
                                }
                              >
                                {/* <img src={subItem.icon} alt={subItem.name} className={'pl-4 pr-[15px] max-w-[50px] max-h-[50px]'} /> */}
                                {subItem.name}
                              </NavLink>
                            ))}
                          </div>
                        </Collapse>
                      </div>
                    </>
                  ) : (
                    <NavLink
                      onClick={() => dispatch(closeSidebar())}
                      to={item.route}
                      className="text-color-white font-normal text-[11px] leading-[10px] flex pl-[20px] h-[42px] items-center hover:text-color-white hover:font-semibold hover:bg-white hover:bg-opacity-5 duration-300"
                      activeClassName={
                        "font-extrabold text-[12px] text-color-white bg-white bg-opacity-10 border-l-4 border-l-white"
                      }
                    >
                      <img
                        src={item.icon}
                        alt={item.name}
                        className={
                          "pr-[15px] w-[29px] h-[35px] duration-300"
                        }
                      />
                      <div className="flex gap-2 items-center text-white">
                        {item.name}
                        {item.status == "new" ? (
                          <div className="h-[10px] w-fit flex items-center justify-center px-2 py-2 bg-red-600 text-white text-[9px] rounded-2xl animate-pulse duration-300">
                            New!
                          </div>
                        ) : null}
                      </div>
                    </NavLink>
                  )}
                </div>
              ))}
            </>
          ) : null}
        </div>
        <div className="flex items-end justify-center italic text-[13px] relative">
          <div className="px-[15px] pt-2">
            <span className="font-bold text-white text-[10px]">
              Current Module:{" "}
            </span>
            <span
              className={
                section?.name === "core hr"
                  ? "bg-white text-[11px] p-1 pr-4 pl-4 text-hr-primary-1 rounded"
                  : section?.name === "payroll"
                  ? "bg-white text-[11px] p-1 pr-4 pl-4 text-secondary-2 rounded"
                  : "bg-white text-[11px] p-1 pr-4 pl-4 text-spend-primary-1 rounded"
              }
            >
              {section?.name === "core hr"
                ? "People Management"
                : section?.name === "payroll"
                ? "Payroll Management"
                : "Spend Management"}{" "}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
